import { 
   Row, 
   Col, 
   Button,
   Empty,
} from 'antd';
import './NoEvents.scss'


const NoEvents = ({ toggleHelp, toggleUploadModal }) => {
   return (
      <Row style={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <Col lg={8}>
            <Empty
               description={
                  <span>Get started uploading your events and adding your tech notes! Click <button className='help' onClick={ toggleHelp }>here</button> for help on getting started.</span>
               }
            >
               <Button type="primary" size='large' block onClick={ toggleUploadModal }>Upload Events</Button>
            </Empty>
         </Col>
      </Row>
   );
}

export default NoEvents