


const splitLines = str => str.split(/\r?\n/);

const getGroup = triggeredRule => {
   const policies = [
      "Execution Prevention",
      "Exfiltration Prevention",
      "Ransomware Prevention",
      "Device Control",
      "eXtended Detection",
      "Server Device Control",
      "Server Execution Prevention",
      "Server Exfiltration Prevention",
      "Server Ransomware Prevention",
      "Simulation Device Control",
   ]

   for (const policy of policies) {
      if (triggeredRule.includes(policy)) {
         const group = triggeredRule.split(policy)[0]    // In case of situation like: "AFS Exfiltration Prevention clone"
         if (group !== '') return group
      }
   }

   return 'Default'
}

const parseEvent = (rawEvent, eventIndex) => {
   const keywords = {}

   const lines = splitLines(rawEvent)
   const lineCount = lines.length
   let defaultHash = ''
   let selectedHash = ''

   // Use the lines of raw string
   for (let i = 0; i < lineCount; i++) {
      const line = lines[i]

      if (line === 'Certificate:') keywords['Certificate'] = lines[i + 1]
      if (line === 'History') keywords['Classification'] = lines[i + 1].split(',')[0]
      if (line === 'Clear All') {
         keywords['EventID'] = lines[i + 1 + 2*eventIndex].split(' ')[1]
         keywords['Process'] = lines[i + 2 + 2*eventIndex]
      }
      if (line === 'LAST SEEN') {
         keywords['Device'] = lines[i + 1]
         keywords['Additional Information'] = lines[i + 5]
      }
      if (line === 'Company:') {
         lines[i + 1] !== 'Description:' 
            ? keywords['Company'] = lines[i + 1] 
            : keywords['Company'] = 'N/A'
      }
      if (line === 'Target:') {
         lines[i + 1] === '\u200e' || lines[i + 1] === 'Company:' 
            ? keywords['Target'] = 'N/A' 
            : keywords['Target'] = lines[i + 1]
      }
      if (line === 'Command Line:') {
         lines[i + 1] !== 'Process Hash (SHA-1):' 
            ? keywords['Command Line'] = lines[i + 1] 
            : keywords['Command Line'] = 'N/A'
      }
      if (line === 'Process Hash (SHA-1):') {
         lines[i + 1] !== 'Process Owner:' && lines[i + 1].length === 40 
            ? defaultHash = lines[i + 1] 
            : defaultHash = 'N/A'
      }
      if (line === 'Analysis Information') {
         lines[i - 1].length === 40 
         ? selectedHash = lines[i - 1] 
         : selectedHash = 'N/A'
      }
      if (line === 'Triggered Rules') keywords['Collector Group'] = getGroup(lines[i + 1])
      if (line === 'User:') {
         lines[i + 1] !== 'Count:' 
            ? keywords['User'] = lines[i + 1].split("\\").at(-1) 
            : keywords['User'] = 'N/A'
      }

      // Add in User if not found
      if (!Object.keys(keywords).includes('User')) keywords['User'] = 'N/A'

      // If we selected a hash, use that, else use the default SHA-1 hash
      if (selectedHash !== "") {
         selectedHash === 'N/A'
         ? keywords['Virus Total Link'] = selectedHash
         : keywords['Virus Total Link'] = `https://www.virustotal.com/gui/search/${ selectedHash }`
      } else {
         defaultHash === 'N/A'
         ? keywords['Virus Total Link'] = defaultHash
         : keywords['Virus Total Link'] = `https://www.virustotal.com/gui/search/${ defaultHash }`
      }
         

      // Setting Meta Information
      keywords['notes']  = ''
      keywords['flagged']     = false
      keywords['key']         = eventIndex
      keywords['originalKey'] = eventIndex
   }

   // Check for valid event
   if (!parseInt(keywords['EventID'])) return false 

   return keywords
} 



export const splitEvents = rawEvents => {
   rawEvents = rawEvents.replace("Event GraphAutomated Analysis", "Event Graph")  // For normalizing the delimeters
   const splitEvents = rawEvents.split("Event Graph")
   splitEvents.pop() // Remove the last item, because we have split expression at very end, thus giving us one extra entry that contains nothing
   
   return splitEvents
}

export const parseEvents = rawEvents => {
   const parsedEvents = []
   let error = false

   const events = splitEvents(rawEvents)   

   // Parse each event
   events.forEach((event, i) => {
      const parsedEvent = parseEvent(event, i)
      parsedEvent
      ? parsedEvents.push(parsedEvent)
      : error = true
   })

   return { parsedEvents, error }
}

export const createTicket = event => {


   let ticket = `
Event ID:                  ${ event['EventID'] }
Process:                   ${ event['Process'] }
Collector Group:      ${ event['Collector Group'] }
Device:                     ${ event['Device'] }
Logged-in User:       ${ event['User'] }
Company:                ${ event['Company'] }
Certification:            ${ event['Certificate'] }
Classification:           ${ event['Classification'] }
Virus Total link:     ${ event['Virus Total Link'] }


Target: ${ event['Target'] }


Command Line: ${ event['Command Line'] }


Additional information: ${ event['Additional Information'] }


Tech notes: ${ event['notes'] }


Next Steps:
Please advise on actions you would like LNX to take on this FortiEDR event notification. If no response is given, we will mark as Unsafe and BLOCK.
`

   if (event['Classification'] === 'Malicious') ticket += `

Future Event Classification:
This process/event is currently classified as MALICIOUS. If this is a normal process
and considered SAFE, please advise so we may prevent future false positive notifications
`

   ticket += '-----------------------------------------------------------------------------------------------\n'

   return ticket
}

export const createTickets = (events, devices) => events.map(event => createTicket(event)).reduce((tickets, ticket) => tickets += ticket) + '\n\n' + devices

