// Custom Component Imports
import Main from './Components/Main/Main';

// Styling imports
import 'antd/dist/antd.min.css'
import './App.scss';

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
