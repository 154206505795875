import { 
   Row, 
   Button,
   Modal,
   Space,
   Drawer,
   Typography,
   Input,
   Menu,
   Dropdown,
   Tooltip,
   message,
} from 'antd';
import { 
   InfoCircleOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { parseEvents, splitEvents } from '../../Functions/Parsing'
import { sortEvents } from '../../Functions/Helpers'

const { Text } = Typography
const { TextArea } = Input;




const UploadModal = ({ visible, setVisible, data, setData, sortBy, toggleHelp, setDevices, devices }) => {

   const [events, setEvents] = useState('');
   const [drawer, setDrawer] = useState(false);
   const [disabled, setDisabled] = useState(false);
   const [currDevices, setCurrDevices] = useState('')

   const IDDoesNotExist = (eventID, events) => {
      return events.every(({ EventID }) => eventID !== EventID)
   }

   const dupMessage = () => {
      message.warning("There were some duplicates, so we removed those :)", 2)
   }

   const updateData = () => {

      const { parsedEvents, error } = parseEvents(events)
      if (error) dupMessage()


      // Remove any duplicates
      let dupError = false
      const validParsedEvents = parsedEvents.filter(({ EventID }) => {
         const valid = IDDoesNotExist(EventID, data)
         if (!valid) dupError = true
         return valid
      })
      if (dupError) dupMessage()


      // Update the keys for ordering and set the data
      validParsedEvents.forEach((event, i) => {
         const newKey = data.length + 1 + i
         event['originalKey'] = newKey
         event['key']         = newKey
      })
      const sortedEvents = sortEvents([...data, ...validParsedEvents], sortBy)
      setData(sortedEvents)
      setVisible(false)
   }

   const clear = () => {
      setEvents('')
      setDevices('')
      setCurrDevices('')
   }

   const cancel = () => {
      setCurrDevices(devices)
      toggleDrawer()
   }

   const saveDevices = () => {
      setDevices(currDevices)
      toggleDrawer()
   }

   const removeLastEvent = () => {
      let newEvents = splitEvents(events)
      newEvents.pop()
      newEvents = newEvents.join("Event Graph") + "Event Graph"
      setEvents(newEvents)
   }

   const toggleDrawer = () => setDrawer(!drawer)

   const menu = (
      <Menu
         items={[
            {
               key: '1',
               label: 'Clear Only Events',
               onClick: () => setEvents('')
            },
            {
               key: '2',
               label: 'Clear Only Devices',
               onClick: () => {
                  setDevices('')
                  setCurrDevices('')
               }
            },
            {
               type: 'divider'
            },
            {
               key: '4',
               label: 'Clear Devices & Events',
               onClick: () => clear()
            },
            {
               key: '5',
               label: 'Remove Last Event',
               onClick: () => removeLastEvent()
            }
         ]}
      />
   );

   return (
      <Modal
         centered
         className="site-drawer-render-in-current-wrapper"
         title={
            <div
               style={{ width: '100%' }}
               onMouseOver={() => { if (disabled) setDisabled(false) }}
               onMouseOut={() => setDisabled(true) }
            >
               <span>Upload Events</span>
            </div>
         }
         footer={
            <Row justify='space-between'>
               <Space>
                  <Tooltip placement='left' title='Clear Events & Devices' mouseEnterDelay={ 0.5 }>
                     <Dropdown.Button 
                     trigger={['click']} 
                     onClick={ clear } 
                     overlay={ menu }>
                        Clear
                     </Dropdown.Button>
                  </Tooltip>
               </Space>
               <Space>
                  <Button onClick={ toggleDrawer }>{ devices === '' ? 'Add' : 'Modify'} Devices</Button>
                  <Button type='primary' onClick={ updateData }>Add</Button>
               </Space>
            </Row>
         }
         bodyStyle={{ padding: '10px 20px' }}
         visible={ visible }
         onOk={ () => setVisible(false) }
         onCancel={ () => setVisible(false) }
      >
         <Space
            direction="vertical"
            size="middle"
         >
            <Text type='secondary'>
               Please consecutively paste your copied events from FortiEDR into the box below. When done, press 'Add' to continue to the Tech Notes section, or directly copy the events without Tech Notes.
               <InfoCircleOutlined className='help-icon' onClick={ toggleHelp }/>
            </Text>
            <Row align='end'>
               <Text style={{ color: '#0047AB' }}>
                  <Text style={{ color: 'inherit' }} strong>{ splitEvents(events).length }</Text> Pasted Events
               </Text>
            </Row>

            <TextArea 
            className='upload-input' 
            value={ events } 
            onChange={ e => setEvents(e.target.value) } />
         </Space>
         <Drawer
         title="Add Devices from the Administration Tab"
         placement="bottom"
         closable={ false }
         onClose={ toggleDrawer }
         visible={ drawer }
         getContainer={ false }
         footer={
            <Row justify='end'>
               <Space>
                  <Button danger onClick={ cancel }>Cancel</Button>
                  <Button type='primary' onClick={ saveDevices }>Save</Button>
               </Space>
            </Row>
         }
         style={{ position: 'absolute' }}>
            <TextArea 
            style={{ height: '100%' }}
            className='' 
            value={ currDevices } 
            onChange={ e => setCurrDevices(e.target.value) } />
         </Drawer>
      </Modal>
   );
}


export default UploadModal