// External Components
import { 
   Row, 
   Col, 
   Button,
   Space,
   Affix,
   message,
   notification,
   Select,
   Popconfirm,
   Typography,
   AutoComplete,
} from 'antd';
import { 
   EditOutlined, 
   DeleteOutlined,
   UploadOutlined,
   PlusSquareOutlined,
   CloseOutlined,
   FlagOutlined,
} from '@ant-design/icons';

// Components
import Navbar from '../../Components/Navbar/Navbar'
import EventCard from '../EventCard/EventCard'
import UploadModal from '../UploadModal/UploadModal'
import NoEvents from '../NoEvents/NoEvents'
import HelpDrawer from '../HelpDrawer/HelpDrawer'
import LoginPage from '../LoginPage/LoginPage';

// Styling
import './Main.scss';

// Functions
import { useState } from 'react';
import { sortEvents } from '../../Functions/Helpers'

const { Option } = Select
const { Text, Title, Paragraph } = Typography


const Main = () => {

   const [devices, setDevices] = useState('');
   const [completedNotes, setCompletedNotes] = useState(false);
   const [helpDrawer, setHelpDrawer] = useState(false);
   const [sortBy, setSortBy] = useState('recent')
   const [uploadModal, setUploadModal] = useState(false);
   const [selectedEvent, setSelectedEvent] = useState(-1);
   const [searchValue, setSearchValue] = useState('');
   const [data, setData] = useState([]);
   const [auth, setAuth] = useState(false);


   const clearEvents = () => {
      setData([])
      setSelectedEvent(-1)
      message.success('Cleared Events')
   }

   const completedNotesNotification = () => {
      notification.success({
         description: 'Successfully completed all Tech Notes',
         placement: 'bottomRight',
         duration: 3,
      })
   }

   const updateEvent = (value, i, key) => {

      // Copy and update the event
      const dataCopy = [...data]
      dataCopy[i][key] = value 
      setData(dataCopy)

      // Alert user if all tech note entries are complete
      if (dataCopy.every(({ notes }) => notes !== '' && notes !== null)) {
         if (!completedNotes) completedNotesNotification()
         setCompletedNotes(true)
      } else {
         setCompletedNotes(false)
      }
   }

   const removeEvent = index => {
     
      // Copy the events and remove the desired event
      const dataCopy = [...data]
      dataCopy.splice(index, 1) 

      // Update the key values and events
      dataCopy.map((event, i) => event['key'] = i)
      setData(dataCopy)
   }

   const selectEvent = (e, i) => {
      // If the dropdown or dropdown contents are clicked
      if ('svg path LI UL SPAN'.includes(e.target.tagName)) return

      const selected = selectedEvent
      setSelectedEvent(-1)

      // Handle event click
      selected === i ? setSelectedEvent(-1) : setSelectedEvent(i)
   }

   const flagEvent = index => {
      updateEvent(!data[index]['flagged'], index, 'flagged')
   }

   // Returns list of events that match the search field criteria
   const searchFilter = () => {

      if (searchValue === '') return data 
      const searchTerms = searchValue.split(' ')

      if (searchTerms.length > 1 && searchTerms[1] !== '') {
         const [ term1, term2 ] = searchTerms
         return data.filter(({ Process: process, EventID: ID }) => {
            const str = `${process} ${ID}`
            return str.includes(term1) && str.includes(term2)
         })
      }
      
      return data.filter(({ Process: process, EventID: ID }) => `${process} ${ID}`.includes(searchValue))
   }

   // Returns list of search autocomplete options to be displayed 
   const searchFilterOptions = (value, option) => {
      const { process, ID } = option
      const searchTerms = value.split(' ')
      const str = `${process} ${ID}`

      // If two search terms are being used
      if (searchTerms.length > 1 && searchTerms[1] !== '') {
         const [ term1, term2 ] = searchTerms
         return str.includes(term1) && str.includes(term2)
      } 

      return str.includes(value)
   }

   const selectOption = data => {
      const [ process, ID ] = data.split(' — ')
      setSearchValue(`${process} ${ID}`)
   }

   const sortDataBy = value => {
      setSortBy(value)
      setData(sortEvents(data, value))
   }

   const toggleHelp = () => setHelpDrawer(!helpDrawer)
   const toggleUploadModal = () => setUploadModal(!uploadModal)

   const fields = [
      'User',
      'Device',
      'Certificate',
      'Company',
      'Collector Group',
      'Classification',
      'Virus Total Link',
      'Target',
      'Command Line',
      'Additional Information',
   ]


   
   return (
      !auth
      ? <LoginPage setAuth={ setAuth } />
      : (
         <div className='container'>
            <Navbar 
            eventCount={ data.length } 
            data={ data } 
            devices={ devices } 
            toggleHelp={ toggleHelp }
            />
            <div className="main">
            
               <div className='toolbar'>
                  <Space size={ 20 }>
                     <AutoComplete
                        options={ data.map(({ Process: process, EventID: ID }) => ({ 
                           value: `${process} — ${ID}`, 
                           process,
                           ID
                        })) }
                        onSelect={ selectOption }
                        clearIcon={ <CloseOutlined /> }
                        allowClear
                        value={ searchValue }
                        placeholder="ID or process..."
                        style={{ minWidth: 500 }}
                        onSearch={ value => setSearchValue(value) }
                        filterOption={ searchFilterOptions }
                     />
                     <Button 
                        icon={ <UploadOutlined /> }
                        onClick={ toggleUploadModal }
                     >Upload Events</Button>

                     <Select style={{ width: 120 }} defaultValue={ 'recent' } onChange={ sortDataBy }>
                        <Option value='recent'>Most Recent</Option>
                        <Option value='alphabetical'>Alphabetical</Option>
                        <Option value='notes'>Tech Notes</Option>
                     </Select>

                     <Popconfirm
                        title="Are you sure?"
                        onConfirm={ clearEvents }
                        okText="Yes"
                        cancelText="No"
                     >
                        <Button danger icon={ <DeleteOutlined /> }>Clear Events</Button>
                     </Popconfirm>
                     
                  </Space>
                  
               </div>
               
               {
                  data.length > 0 && 
                  <Row gutter={ 20 } style={{ minWidth: 950 }}>
                        <Col md={ selectedEvent >= 0 ? 10 : 20 } lg={ selectedEvent >= 0 ? 12 : 20 } xl={ selectedEvent >= 0 ? 15 : 20 }>
                           <Space
                              direction="vertical"
                              size="middle"
                              style={{ display: 'flex' }}
                           >
                              { searchFilter().map(event => (
                                 <div 
                                 key={ `event-card-${event['key']}` } 
                                 className={ `event-card-container ${selectedEvent === event['key'] ? 'highlight' : ''}` }
                                 onClick={ e => selectEvent(e, event['key']) }>
                                    <EventCard 
                                    removeEvent={ removeEvent }
                                    flagEvent={ flagEvent }
                                    event={ event } 
                                    updateEvent={ updateEvent } 
                                    index={ event['key'] }/>
                                 </div>
                              )) }
                           </Space>
                        </Col>
                     {
                        selectedEvent >= 0 &&
                        <Col md={ 8 } lg={ 12 } xl={ 9 }>
                           <Affix offsetTop={120}>
                              <div>
                                 <Space align='center' size={ 15 }>
                                    <Title 
                                    style={{ maxWidth: 450 }}
                                    ellipsis={{ tooltip: `${ data[selectedEvent]['EventID'] } — ${ data[selectedEvent]['Process'] }` }} 
                                    level={ 3 }>
                                       { data[selectedEvent]['EventID'] } — { data[selectedEvent]['Process'] }
                                    </Title>
                                    { data[selectedEvent]['flagged'] && <FlagOutlined style={{ fontSize: 20, marginBottom: 12, color: 'orange' }} /> }
                                 </Space>

                                 { fields.map(key => (
                                    <div className="grid-container" key={ key }>
                                       <div className="grid-item header">{ key }:</div>
                                       <div className="grid-item content">
                                          <Text 
                                             style={{ width: '100%' }}
                                             ellipsis={{ tooltip: data[selectedEvent][key] }}
                                             className='ticket-field'
                                             editable={{
                                                onChange: newValue => updateEvent(newValue, selectedEvent, key),
                                                triggerType: ['icon', 'text'],
                                                enterIcon: <Text style={{ marginBottom: '-3px', marginRight: '-4px' }} code>enter</Text>,
                                                icon: <EditOutlined />
                                             }}
                                          >{ data[selectedEvent][key] }</Text>
                                       </div>
                                    </div>
                                    
                                 ))}

                                 <div className="grid-container">
                                    <div className="grid-item header">
                                       Tech Notes:
                                    </div>
                                    <div className="grid-item content">
                                       <Paragraph 
                                          className='ticket-field'
                                          ellipsis={{ rows: 3, tooltip: data[selectedEvent]['notes'] }}
                                          editable={{
                                             onChange: newValue => updateEvent(newValue, selectedEvent, 'notes'),
                                             triggerType: ['icon', 'text'],
                                             tooltip: 'Add Notes',
                                             enterIcon: <Text style={{ marginBottom: '-3px', marginRight: '-4px' }} code>enter</Text>,
                                             icon: data[selectedEvent]['notes'] === '' || data[selectedEvent]['notes'] === null ? <PlusSquareOutlined /> : <EditOutlined />
                                          }}
                                       >{ data[selectedEvent]['notes'] }</Paragraph>
                                    </div>
                                 </div>
                              </div>
                              
                              
                           </Affix>
                        </Col>
                     }
                     
                  </Row>
               }



               {
                  data.length === 0 && 
                  <NoEvents 
                     toggleHelp={ toggleHelp }
                     toggleUploadModal={ toggleUploadModal }
                  />
               }
               

               <UploadModal 
                  setVisible={ setUploadModal }
                  visible={ uploadModal }
                  data={ data }
                  setData={ setData }
                  sortBy={ sortBy }
                  toggleHelp={ toggleHelp }
                  setDevices={ setDevices }
                  devices={ devices }
               />

               <HelpDrawer toggleVisible={ toggleHelp } visible={ helpDrawer } />
            </div>
         </div>
      )
   );
}
 
export default Main;