

export const sortEvents = (data, value) => {
   const dataCopy = [...data]
   let newData
   
   if (value === 'recent') {
      dataCopy.sort((a, b) => a['originalKey'] - b['originalKey'])
      newData = [...dataCopy]
   }
   if (value === 'alphabetical') {
      dataCopy.sort((a, b) => {
         const x = a['Process'].toLowerCase();
         const y = b['Process'].toLowerCase();
         if (x < y) return -1
         if (x > y) return 1
         return 0;
      })
      newData = [...dataCopy]
   }
   if (value === 'notes') {
      const completed =       dataCopy.filter(({ notes }) => notes !== '' && notes != null)
      const uncompleted =     dataCopy.filter(({ notes }) => notes === '' || notes == null)
      newData = [...uncompleted, ...completed]
   } else {
   }
   newData.forEach((event, i) => event['key'] = i)
   
   return newData
}