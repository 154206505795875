import { 
   Result,
   Space,
   Alert,
   Drawer,
   Steps,
   Typography,
   Divider,
} from 'antd';
import { 
   SmileOutlined,
} from '@ant-design/icons';

const { Text, Title, Paragraph } = Typography
const { Step } = Steps;


const HelpDrawer = ({ visible, toggleVisible }) => {
   return (
      <Drawer
         size='large'
         title="Help Information"
         placement="right"
         key='right'
         closable={ true }
         onClose={ toggleVisible }
         visible={ visible }
         getContainer={ true }
         style={{ position: 'absolute' }}>
            <Title level={4}>Getting Started</Title>
            <Space direction='vertical' size={'large'}>
               <Paragraph>This website is designed to aid in ticket/event anaylsis. It is meant to be used in conjunction with FortiEDR forensics and the NOC Ticketing Application. It's simple to use, but has a few caveats that are discussed below. You can see the usage flow below, i.e., what actions you will take to use this website.</Paragraph>
               <Steps current={3}>
                  <Step title="Step 1" description="Sequentially copy FortiEDR forensics page for each event." />
                  <Step title="Step 2" description="Paste all raw event data into this website, fill in tech notes, and copy the resulting tickets." />
                  <Step title="Step 3" description="Paste the parsed tickets into the NOC Ticketing Application." />
               </Steps>
            </Space>
            <Divider />
            <Space direction='vertical' size={12}>
               <Title level={4}>Uploading Events</Title>
               <Paragraph>Here you will find a more detailed explanation on uploading events into this tool. Below are the steps to follow for each event you want to use.</Paragraph>
               <Steps direction='vertical' current={5}>
                  <Step title='Open in Forensics' description='From the Event Viewer page, select all your desired events and open them in the Forensics tab and select Stacks View' />
                  <Step title='Select step where rule was broken' description='Ensure that you have selected the step with the red dot in the event execution timeline. This represents the step where the process broke the Fortinet rule.' />
                  <Step title='Select file path where rule was broken' description='For the sake of a correct Virus Total Link, you need to click/open the row that has a red line on the far right. This represets the file that broke the Fortinet rule' />
                  <Step title='Open additional information' description='Lastly, you must open the Classification Details with the "i" button in the far right, under the strip where event ID tabs are location. This will allow us to get the Collector Group' />
               </Steps>
               <Paragraph>After you've completed these steps, you can <Text code>ctrl + a</Text> and <Text code>ctrl + c</Text> to copy all necessary information on the page for the given event. Then you can paste it into the "Upload Events" section on this website.</Paragraph>
               <Alert type='warning' showIcon message={ <Text><Text strong>Note:</Text> Make sure that you perform this process for the events <Text strong italic>in the order they appear</Text> in the tabs at the top of the forensics page. If this is not done properly, the events will not be associated with the correct IDs.</Text> } />
               <Paragraph>You can also add the licensing and device information in the upload modal by selecting the <Text strong>Add Devices</Text> button. Lastly, you can select <Text strong>Add</Text> in order to parse the raw data.</Paragraph>
            </Space>
              
            <Divider />

            <Title level={4}>Adding Tech Notes</Title>
            <Paragraph>Adding Tech Notes is simple once you've uploaded and parsed the data. You can select an event ticket and all its information will be editable on the right side. The Tech Notes are located at the bottom of this information. Select the event ticket again to close it. Once all Tech Notes are complete, you will recieve a notification in the bottom left.</Paragraph>
            <Alert type='info' showIcon message={ <Text><Text strong>Note:</Text> You do not have to complete Tech Notes to save the parsed tickets. You can copy/download the parsed data with empty Tech Notes if you'd like to fill them in elsewhere. This simply provides you a means to organize and keep track of them better.</Text> } />

            <Divider />

            <Title level={4}>Saving Tickets</Title>
            <Paragraph>Though this is arguably the most important part, it is also the easiest. In order to save your parsed event tickets, you can  copy them all to your clipboard by clicking the <Text strong>Copy Tickets</Text> button in the top right of the page, next to the event count. Additionally, you can download a text file with them all by selecting the ellipsis on the button.</Paragraph>
            <Alert type='info' showIcon message={ <Text><Text strong>Note:</Text> You'll notice the resulting ticket values are staggered in a visually unappealing manner. However, this staggaring is spaced to look <Text italic>visually appealing</Text> once pasted into the NOC Ticking Application. Try it out!</Text> } />

            <Divider />

            <Space direction='vertical' size={10}>
               <Title level={4}>Searching for Events</Title>
               <Paragraph>You can search through your parsed event tickets using the search bar. It takes input in the form of the three options below.</Paragraph>
               <Steps progressDot current={3}>
                  <Step title='Process Name' description='(Exact match)' />
                  <Step title='Event ID' description='(Exact match)' />
                  <Step title='Process Name & Event ID' description='(Separate by space)' />
               </Steps>
            </Space>

            <Divider />

            <Title level={4}>Sorting Events</Title>
            <Paragraph>You can sort your events in three ways. You choose a sort method by selecting an option from the drop down next to the Upload Events button on the toolbar.</Paragraph>
            <Steps direction='vertical' progressDot current={5}>
               <Step title='Most Recent' description='Sort in the order they were added/parsed' />
               <Step title='Alphabetically' description='Sort in alphabetical order' />
               <Step title='Tech Notes' description='Sort with completed at the bottom and uncompleted at the top' />
            </Steps>

            <Divider />

            <Title level={4}>Extra Event Ticket Options</Title>
            <Paragraph>There are a few extra options for each event ticket that you can see by selecting the gear at the top right of each event. You can parse and copy an individual event ticket, flag an individual event, or remove an individual event (This cannot be undone).</Paragraph>
            <Divider />
            <Result
               icon={<SmileOutlined />}
               status='success'
               title='Thank you for reading, you are ready to go!'
               subTitle='Please do not hesitate to ask more questions or give recommendations to me'
            />
      </Drawer>
   );
}
 
export default HelpDrawer;