import { 
   Button, 
   PageHeader, 
   Typography, 
   Space, 
   Menu,
   Dropdown,
   message, 
} from "antd";
import {
   CopyOutlined,
   DownloadOutlined,
} from '@ant-design/icons'
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { createTickets } from '../../Functions/Parsing';
import './Navbar.scss'


const { Text } = Typography





const Navbar = ({ eventCount, data, devices, toggleHelp }) => {

   const [loading, setLoading] = useState(false);



   const copyTickets = () => {
      setLoading(true)
      const tickets = createTickets(data, devices + '\n\n')
      navigator.clipboard.writeText(tickets)
      setLoading(false)
      message.success('Copied Tickets to Clipboard!')
   }

   const downloadTickets = () => {
      setLoading(true)
      const tickets = createTickets(data, devices + '\n\n')
      const blob = new Blob([tickets], { type: 'text/plain;charset=utf-8'})
      saveAs(blob, 'tickets.txt')
      setLoading(false)
      message.success('Downloaded Tickets!')
   }

   
   const menu = (
      <Menu
         items={[
            {
               key: '1',
               label: 'Copy Tickets',
               icon: <CopyOutlined />,
               onClick: () => copyTickets()
            },
            {
               key: '2',
               label: 'Download Tickets',
               icon: <DownloadOutlined />,
               onClick: () => downloadTickets()
            },
         ]}
      />
   );


   return (
      <PageHeader 
         style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000, backgroundColor: '#fff' }}
         title='Liquid Networx'
         subTitle='Ticket Analysis'
         extra={
            <Space size='large'>
               <Text type="secondary" >{ eventCount } Events</Text>
               <Button onClick={ toggleHelp }>Help</Button>
               <Dropdown.Button onClick={ copyTickets } disabled={ eventCount === 0 } loading={ loading } type="primary" overlay={ menu }>
                  Copy Tickets!
               </Dropdown.Button>
            </Space>
         }
      />
   );
}
 
export default Navbar;