
import { 
   Row, 
   Col, 
   Card,
   Tag,
   Dropdown,
   Menu,
   Space,
   message,
   Typography,
   Button,
   notification,
} from 'antd';
import { 
   SettingOutlined, 
   CopyOutlined,
   DeleteOutlined,
   BugOutlined,
   CheckCircleOutlined,
   FlagFilled,
   FlagOutlined,
   SearchOutlined,
} from '@ant-design/icons';
import { createTicket } from '../../Functions/Parsing'
import GetIP from '../../APIs/GetIP';

const { Text } = Typography





const EventCard = ({ event, flagEvent, removeEvent, index }) => {


   const locateIP = async () => {

      // Query the IP API
      const IP = event['Additional Information']
      const response = await GetIP(IP)
      const key = `open${Date.now()}`;
      let description = ''
      let message = ''
      let type = ''


      // Check for success
      if (!response['error'] && response['status'] === 200) {
         const { region, country_name, city } = response
         message = country_name
         description = (
            <table className='IP-table'>
               <tbody>
                  <tr>
                     <td><Text strong>City:</Text></td>
                     <td>{ city }</td>
                  </tr>
                  <tr>
                     <td><Text strong>Region:</Text></td>
                     <td>{ region }</td>
                  </tr>
                  <tr>
                     <td><Text strong>Country:</Text></td>
                     <td>{ country_name }</td>
                  </tr>
               </tbody>
            </table>
         )
         
         type = 'success'
      } 
      else if (response['error'] || response['status'] !== 200) {
         const { reason } = response

         // Construct message
         if (reason === 'Reserved IP Address') {
            message = 'Reserved Range'
            description = 'This IP falls under a reserved range and is not loctable'
         }
         if (reason === 'Invalid IP Address') {
            message = 'Invalid IP'
            description = 'An invalid IP has been provided, please check that the IP is valid'
         }

         type = 'warning'
      } else return


      // Open the notification
      notification[type]({
         message: <Text strong style={{ fontSize: '1.1em' }}>{ message }</Text>,
         description,
         key,
         placement: 'bottomLeft',
         duration: 7,
      })
   }

   const openVirusTotal = () => {
      window.open(event['Virus Total Link'])
   }


   const menu = (
      <Menu
         items={[
            {
               key: '1',
               label: 'Copy Event',
               icon: <CopyOutlined />,
               onClick: () => copyEvent()
            },
            {
               key: '2',
               label: event['flagged'] ? 'Unflag' : 'Flag',
               icon: <FlagOutlined style={{ color: event['flagged'] && 'orange' }} />,
               onClick: () => flagEvent(index)
            },
            {
               key: '3',
               label: 'Locate IP',
               icon: <SearchOutlined />,
               onClick: () => locateIP()
            },
            {
               key: '4',
               label: 'Virus Total',
               icon: <BugOutlined />,
               onClick: () => openVirusTotal()
            },
            {
               key: '5',
               label: 'Remove',
               icon: <DeleteOutlined />,
               danger: true,
               onClick: () => removeEvent(index)
            },
            
         ]}
      />
   )

   const copyEvent = () => {
      const ticket = createTicket(event)
      navigator.clipboard.writeText(ticket)
      message.success('Copied Event to Clipboard!')
   }

   const getClassificationColor = classification => {
      const colors = {
         Malicious: 'red',
         Suspicious: 'red',
         Safe: 'cyan',
         Inconclusive: 'orange',
         PUP: 'purple',
      }
      colors['PUP!'] = 'purple'
      colors['Likely Safe'] = 'blue'

      return colors[classification]
   }

   return (
      <button className='event-card'>
         <Card 
            extra={
               <Space size={ 15 }>
                  {
                     event['notes'] && 
                     <Tag icon={ <CheckCircleOutlined /> } color={ '#4CBB17' } style={{ marginBottom: 2 }}> 
                        Tech Notes
                     </Tag>
                  }
                  <Dropdown overlay={menu} trigger={['click']}>
                     <a className='settings' onClick={ e => e.preventDefault() }>
                        <SettingOutlined className='event-settings' />
                     </a>
                  </Dropdown>
               </Space>
            }
            title={ 
               <Space size={ 10 }>
                  <Text 
                  style={{ maxWidth: 400 }}
                  ellipsis={{ tooltip: `${ event['Process'] } — ${ event['EventID'] }` }}
                  >{ event['EventID'] } — { event['Process'] }</Text>
                  { event['flagged'] &&  <FlagFilled style={{ fontSize: 14, color: 'orange' }} /> }
               </Space>
            }
            type='inner'
         >

            <Row wrap>
               <Col xs={ 12 } style={{ minWidth: 250 }}>
                  <table className='card-table'>
                     <tbody>
                        <tr>
                           <th>User:</th>
                           <td>{ event['User'] }</td>
                        </tr>
                        <tr>
                           <th>Device:</th>
                           <td>{ event['Device'] }</td>
                        </tr>
                        <tr>
                           <th>Certification:</th>
                           <td>{ event['Certificate'] }</td>
                        </tr>
                     </tbody>
                  </table>
               </Col>
               <Col xs={ 12 } style={{ minWidth: 250 }}>
                  <table>
                     <tbody>
                        <tr>
                           <th>Company:</th>
                           <td>{ event['Company'] }</td>
                        </tr>
                        <tr>
                           <th>Collector Group:</th>
                           <td>{ event['Collector Group'] }</td>
                        </tr>
                        <tr>
                           <th>Classification:</th>
                           <td><Tag style={{ marginRight: 0, marginTop: 5 }} color={ getClassificationColor(event['Classification'] )}>{ event['Classification'] }</Tag></td>
                        </tr>
                     </tbody>
                  </table>
               </Col>
               
               
            </Row>

         </Card>
      </button>
   );
}


export default EventCard