// External Components
import {
   Space,
   Button
} from 'antd'
import { 
   EyeInvisibleOutlined, 
   EyeOutlined, 
} from '@ant-design/icons';

// Functions
import { useState } from 'react'

// Styling
import './LoginPage.scss'


const LoginPage = ({ setAuth }) => {

   const [input, setInput] = useState('')
   const [hide, setHide] = useState(true)
   const [focus, setFocus] = useState(false)
   const [error, setError] = useState(false)


   const login = e => {
      e.preventDefault()
      input === process.env.REACT_APP_PASSWORD
      ? setAuth(true)
      : setError(true)
   }

   const updateInput = e => {
      if (error && e.target.value !== input) setError(false)
      setInput(e.target.value)
   }

   const toggleHide = () => setHide(!hide)



   return (  
      <div className='login-page'>
         <div className="login">
            <form onSubmit={ login }>
               <Space direction='vertical' size={ 'large' }>
                  <h1 level={ 2 }>Sign In</h1>
                  <div className='input-field'>
                     <input 
                     type={ hide ? 'password' : 'text' } 
                     onFocus={ () => setFocus(true) }
                     onBlur={ () => setFocus(false) }
                     onChange={ updateInput } />
                     {
                        hide
                        ? <EyeInvisibleOutlined onClick={ toggleHide } style={{ color: '#fff', fontSize: 25 }} />
                        : <EyeOutlined onClick={ toggleHide } style={{ color: '#fff', fontSize: 25 }} /> 
                     }
                  </div>
                  <div className={ `bar ${ focus ? 'focus' : '' } ${ error ? 'error' : '' }` }></div>
                  <Button onClick={ login } type='primary'>Login</Button>
               </Space>
            </form>
         </div>
      </div>
   );
}
 
export default LoginPage;